import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export function ScrollToHashElement() {
  const { hash } = useLocation()
  const { historyId } = useParams()

  useEffect(() => {
    if (hash) {
      const id = hash.replace('#', '')

      const findElement = () => {
        const element = document.getElementById(id)

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        } else {
          setTimeout(findElement, 100) // Retry after a short delay
        }
      }

      findElement()
    }
  }, [hash])

  useEffect(() => {
    if (!hash && historyId) {
      // scroll top
      window.scrollTo(0, 0)
    }
  }, [hash, historyId])

  return null // This component doesn't render anything itself
}
