/* eslint-disable max-classes-per-file */
import { memoize } from 'lodash'
import { User } from 'src/entities/account'
import { AiModels } from 'src/entities/ai'
import { httpClient } from './apiClients'

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

type UpdateEntityParams<T> = RecursivePartial<T>
// export type HttpResponse<T = unknown, D = unknown> = Promise<T, D>

type API<T, C = T, U = UpdateEntityParams<T>, Q = unknown> = {
  readonly url: string
  fetch?(params?: Q): Promise<T[]>
  get?: (id: string) => Promise<T>
  create?(params: C): Promise<T>
  update?(id: string, data: U): Promise<T>
  delete?(id: string): Promise<unknown>
}

export type ServerResponse<T = unknown> = T

class AccountsApi implements API<User> {
  url = '/account/current'

  getCurrent = () => httpClient.get<ServerResponse<User>>(this.url).then((data) => data)
}

class CouponsApi implements API<User> {
  url = '/account/coupon/'

  validateCoupon = ({ id, activateState = 0 }) =>
    httpClient.post<ServerResponse<any>>(`${this.url}${id}?activate=${activateState}`).then((data) => data)

  activateCoupon = ({ id, activateState = 1 }) =>
    httpClient.post<ServerResponse<any>>(`${this.url}${id}?activate=${activateState}`).then((data) => data)
}

class AuthApi implements API<User> {
  url = '/account/'

  sendEmail = (params) => httpClient.post<ServerResponse<User>>(`${this.url}code`, params).then((data) => data)

  verifyCode = (params) =>
    httpClient.post<ServerResponse<{ token: string }>>(`${this.url}auth`, params).then((data) => data)

  logout = () => httpClient.post<ServerResponse<{ token: string }>>(`${this.url}logout`).then((data) => data)
}

class AIModelsApi implements API<AiModels> {
  url = '/ai/'

  getModels = () => httpClient.get<ServerResponse<AiModels>>(`${this.url}config`).then((data) => data)
}

class BlogApi implements API<any> {
  url = '/blog/'

  getArticles = ({ page }) =>
    httpClient.get<ServerResponse<any>>(`${this.url}get?page=${page}&limit=20`).then((data) => data)

  getArticleById = (id) => httpClient.get<ServerResponse<AiModels>>(`${this.url}get/${id}`).then((data) => data)
}

class TaskApi implements API<any> {
  url = '/task/'

  runTask = (id, params) => httpClient.post<ServerResponse<any>>(`${this.url}run/${id}`, params).then((data) => data)

  getTaskById = (id) => httpClient.get<ServerResponse<AiModels>>(`${this.url}get/${id}`).then((data) => data)

  searchByQuery = ({ query }) =>
    httpClient.get<ServerResponse<any>>(`${this.url}search?q=${query}&limit=30`).then((data) => data.items)

  getCoreModels = () => httpClient.get<ServerResponse<AiModels>>(`${this.url}core}`).then((data) => data)

  getTaskBySlug = (slug) => httpClient.get<ServerResponse<any>>(`${this.url}get-by-slug/${slug}`).then((data) => data)
}

class PinsApi implements API<any> {
  url = '/task/pin/'

  addPin = (id) => httpClient.get<ServerResponse<any>>(`${this.url}add/${id}`).then((data) => data)

  removePin = (id) => httpClient.get<ServerResponse<any>>(`${this.url}remove/${id}`).then((data) => data)

  getAllPinnedTasks = () => httpClient.get<ServerResponse<any>>(`${this.url}list?limit=50`).then((data) => data.items)
}

class SubscriptionApi implements API<any> {
  url = '/account/'

  getSubscriptionLink = () => httpClient.get<ServerResponse<any>>(`${this.url}subscription`).then((data) => data)
}

class HistoryApi implements API<any> {
  url = '/task/history/'

  searchByQuery = (query) =>
    httpClient
      .get<ServerResponse<{ items: any[] }>>(`${this.url}search?q=${query || ''}&limit=50`)
      .then((data) => data.items)

  getHistoryItemById = (id) => httpClient.get<ServerResponse<any>>(`${this.url}get/${id}`).then((data) => data)
}

class StripeApi implements API<any> {
  url = '/account/'

  getSubscription = () => httpClient.get<ServerResponse<any>>(`${this.url}subscription`).then((data) => data)
}

// class ModelsApi implements API<AIModel> {
//   url = '/character/'

//   getModels = () => httpClient.get<ServerResponse<AIModel>>(`${this.url}list`).then((data) => data)

//   deleteModel = (id) => httpClient.post<ServerResponse<AIModel>>(`${this.url}delete/${id}`).then((data) => data)

//   trainModel = (id) => httpClient.post<ServerResponse<AIModel>>(`${this.url}train/${id}`).then((data) => data)

//   createModel = (params) => httpClient.post<ServerResponse<AIModel>>(`${this.url}create`, params).then((data) => data)
// }

class APIClient {
  accounts = memoize(() => new AccountsApi())

  auth = memoize(() => new AuthApi())

  pins = memoize(() => new PinsApi())

  tasks = memoize(() => new TaskApi())

  history = memoize(() => new HistoryApi())

  aiModels = memoize(() => new AIModelsApi())

  blogs = memoize(() => new BlogApi())

  stripe = memoize(() => new StripeApi())

  subscription = memoize(() => new SubscriptionApi())

  coupons = memoize(() => new CouponsApi())
}

export const apiClient = new APIClient()
