/* eslint-disable camelcase */
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
// import ReactGA from 'react-ga4'
import { useSearchParams } from 'react-router-dom'
import { User } from 'src/entities/account'

export const useAnalytics = () => {
  // const posthog = usePostHog()
  const [searchParams] = useSearchParams()
  const ref_id = searchParams.get('ref_id')
  const utm_medium = searchParams.get('utm_medium')
  const utm_source = searchParams.get('utm_source')
  const utm_campaign = searchParams.get('utm_campaign')
  const utm_term = searchParams.get('utm_term')

  useEffect(() => {
    if (!ref_id) return

    localStorage.setItem('refId', ref_id as string)
  }, [ref_id])

  useEffect(() => {
    try {
      const adsSource = localStorage.getItem('adsSource') || null

      if (adsSource) return

      localStorage.setItem(
        'adsSource',
        JSON.stringify({
          utm_medium,
          utm_source,
          utm_campaign,
          utm_term,
          // eslint-disable-next-line
        }),
      )
    } catch (error) {
      console.log(error)
    }
  }, [])

  const fireEvent = (eventName: ANALYTICS_EVENTS, params: Record<string, string> = {}) => {
    // posthog.capture(eventName, params)
    sendGAEvent(eventName, params)
  }

  const identify = (user: User) => user

  return {
    fireEvent,
    identify,
  }
}

export enum ANALYTICS_EVENTS {
  Login = 'sign_in',
  SignUp = 'sign_up',
  ClickUpsell = 'ClickUpsell',
  Logout = 'Logout',
  ClickSubscribe = 'ClickSubscribe',
  ClickManagePlan = 'ClickManagePlan',
  ClickBuyCredits = 'ClickBuyCredits',
  CouponActivated = 'coupon_activated',
  ClickBuySubscription = 'ClickBuySubscription',
}

export const sendGAEvent = (eventName, eventParams = {}) => {
  ReactGA.event({
    category: 'User',
    action: eventName,
    ...eventParams,
  })
}
