import { useMutation } from 'react-query'
import { apiClient } from 'src/api/api-client'
import { useModelApi } from 'src/api/core'
import { ANALYTICS_EVENTS, sendGAEvent } from 'src/hooks/useAnalytics'
import { queryClient } from 'src/queries/reactQueryClient'
import { pushNotification } from 'src/services/notifications/notificationService'
import { QUERY_KEY_CURRENT_ACCOUNT } from '../account/queryKeys'
import { QUERY_KEY_COUPONS } from './queryKeys'

export const useCoupons = (props) => {
  const client = apiClient.coupons()

  const query = useModelApi({
    ...props,
    id: 'couponDetails',
    enabled: !!props?.id,
    cacheTime: 0,
    queryKey: [QUERY_KEY_COUPONS, props?.id] as unknown as string,
    queryFn: () => client.validateCoupon({ id: props?.id }),
    onError: () => {
      pushNotification({ type: 'error', title: 'Unknown error' })
    },
  })

  const activateCouponMutation = useMutation(client.activateCoupon, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY_CURRENT_ACCOUNT)
      sendGAEvent(ANALYTICS_EVENTS.CouponActivated)
    },
  })

  return {
    ...query,
    activateCoupon: { ...activateCouponMutation },
  }
}
