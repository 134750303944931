export const locale = {
  'en-US': {
    'explore.action': 'Explore more',

    'languages.english': 'English',
    'languages.russian': 'Русский',
    'languages.thai': 'ภาษาไทย',

    'common.loadMore': 'Load more',
    'common.copyNegative': 'Copy negative',
    'login.verification.resendText': 'Resend code in {seconds} seconds',
    'login.verification.resendAction': 'Resend code',

    'server.error.notAuthorized': 'You are not authorized.',
    'server.error.unhandledMessage': 'Unknown error.',
    'server.error.AuthMissing': 'Invalid code',
    'server.error.Ready': 'Character is not ready yet',
    'server.error.InsufficientBalance': 'Not enough balance',
    'server.error.concurrencyOverflow': 'Too many parallel requests',
    'server.error.couponUsed': 'Coupon has been used',
  },
  'ru-RU': {
    'explore.action': 'Explore more',

    'languages.english': 'English',
    'languages.russian': 'Русский',
    'languages.thai': 'ภาษาไทย',

    'common.loadMore': 'Load more',
    'common.copyNegative': 'Copy negative',

    'server.error.notAuthorized': 'Вы не авторизованы.',
    'server.error.unhandledMessage': 'Неизвестная ошибка.',
    'server.error.AuthMissing': 'Неверный код',
    'server.error.Ready': 'Персонаж еще не готов',
    'server.error.InsufficientBalance': 'Недостаточно средств',
    'server.error.couponUsed': 'Купон был использован',
  },
  'th-TH': {
    'explore.action': 'Explore more',

    'common.loadMore': 'Load more',
    'common.copyNegative': 'Copy negative',

    'languages.english': 'English',
    'languages.russian': 'Русский',
    'languages.thai': 'ภาษาไทย',
    'server.error.notAuthorized': 'คุณไม่ได้รับอนุญาต',
    'server.error.unhandledMessage': 'ข้อผิดพลาดที่ไม่รู้จัก',
    'server.error.AuthMissing': 'รหัสไม่ถูกต้อง',
    'server.error.Ready': 'ตัวละครยังไม่พร้อม',
    'server.error.InsufficientBalance': 'ยอดเงินไม่พอ',
    'server.error.concurrencyOverflow': 'คำขอพร้อมกันมากเกินไป',
    'server.error.couponUsed': 'Coupon has been used',
  },
}
