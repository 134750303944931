/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from 'react'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { useCoupons } from 'src/entities/coupons/useCoupons'
import { useServerNotification } from 'src/hooks/serverNotification.hook'
import { sendGAEvent } from 'src/hooks/useAnalytics'
import { pushNotification } from 'src/services/notifications/notificationService'
import { DateFormats, formatDate } from 'src/utils/dates'
import styles from './index.module.scss'

export function CouponsWidget() {
  const [coupon, setCoupon] = useState('')
  const [couponId, setCouponId] = useState<string | null>(null)
  // eslint-disable-next-line
  const { activateCoupon, data, isLoading: isLoadingCouponData } = useCoupons({ id: couponId })
  const { mutateAsync, isLoading } = activateCoupon
  const { notify } = useServerNotification()

  const onSetCouponId = (e) => {
    e.preventDefault()
    setCouponId(coupon)
    sendGAEvent('coupon_check')
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      await mutateAsync({ id: coupon })
      pushNotification({
        type: 'success',
        title: 'Coupon activated',
      })
      setCouponId(null)
    } catch (e) {
      notify(e as any)
      sendGAEvent('coupon_error')
    }
  }

  return (
    <div className={styles.box}>
      <form onSubmit={onSetCouponId} className={styles.container}>
        <Input
          disabled={isLoadingCouponData || isLoading}
          placeholder="Coupon code"
          value={coupon}
          onChange={(e) => setCoupon(e.target.value)}
        />
        <Button isLoading={isLoadingCouponData} label="Get details" type="submit" />
      </form>

      {data && couponId ? (
        <div className={styles.couponContainer}>
          <div className={styles.details}>Coupon details</div>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <div className={styles.couponTitle}>Subscription plan: {data?.sub_plan}</div>
          <div className={styles.info}>
            <div className={styles.periodOd}>
              Period:&nbsp;
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {data?.period_len}&nbsp;
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {data?.period_type}
            </div>
            <div className={styles.period}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              Till {formatDate((data?.sub_till * 1000) as Date, DateFormats.isoDate)}
            </div>
          </div>
          <div className={styles.action}>
            <Button isLoading={isLoading} onClick={onSubmit} type="button" label="Activate" />
          </div>
          <div className={styles.close}>
            <Button
              icon={IconNames.close}
              variant={ButtonVariants.unstyled}
              size={ButtonSizes.xs}
              type="button"
              iconColor="var(--color-text)"
              label=""
              onClick={() => setCouponId(null)}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
